import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import { GetDashboardRequest } from 'models/dashboard/GetDashboardRequest';
import { GetDashboardResponse } from 'models/dashboard/GetDashboardResponse';
import { GetBusinessUnitByIdRequest } from 'models/organizations/requests/GetBusinessUnitByIdRequest';
import { CreateBusinessUnitRequest } from 'models/organizations/requests/CreateBusinessUnitRequest';
import { UpdateBusinessUnitRequest } from 'models/organizations/requests/UpdateBusinessUnitRequest';
import { CreateCriticalityTierRequest } from '../models/organizations/requests/CreateCriticalityTierRequest';
import { CreateEnvironmentTypeRequest } from '../models/organizations/requests/CreateEnvironmentTypeRequest';
import { CreateOrganizationRequest } from '../models/organizations/requests/CreateOrganizationRequest';
import { Organization } from '../models/organizations/Organization';
import { CreateProductRoleRequest } from '../models/organizations/requests/CreateProductRoleRequest';
import { GetCriticalityTierByIdRequest } from '../models/organizations/requests/GetCriticalityTierByIdRequest';
import { GetEnvironmentTypeByIdRequest } from '../models/organizations/requests/GetEnvironmentTypeByIdRequest';
import { GetOrganizationByIdRequest } from '../models/organizations/requests/GetOrganizationByIdRequest';
import { GetProductRoleByIdRequest } from '../models/organizations/requests/GetProductRoleByIdRequest';
import { ProductRole } from '../models/organizations/ProductRole';
import { UpdateCriticalityTierRequest } from '../models/organizations/requests/UpdateCriticalityTierRequest';
import { UpdateEnvironmentTypeRequest } from '../models/organizations/requests/UpdateEnvironmentTypeRequest';
import { UpdateProductRoleRequest } from '../models/organizations/requests/UpdateProductRoleRequest';
import { CreateAzureRmPlatformRequest } from '../models/platforms/requests/CreateAzureRmPlatformRequest';
import { CreateGitHubPlatformRequest } from '../models/platforms/requests/CreateGitHubPlatformRequest';
import { GetPlatformByIdRequest } from '../models/platforms/requests/GetPlatformByIdRequest';
import { Platform } from '../models/platforms/Platform';
import { PlatformListRequest } from '../models/platforms/requests/PlatformListRequest';
import { GetProductByIdRequest } from '../models/products/GetProductByIdRequest';
import { GetProductByIdResponse } from '../models/products/GetProductByIdResponse';
import { ProductListRequest } from '../models/products/ProductListRequest';
import { ProductListResponse } from '../models/products/ProductListResponse';
import { UpdateTenantIdForAzureAdPlatformRequest } from '../models/platforms/requests/CreateAzureAdPlatformRequest';
import { EnableProductRoleRequest } from '../models/products/EnableProductRoleRequest';
import { EnableProductRoleResponse } from '../models/products/EnableProductRoleResponse';
import { BadRequestResponse } from '../models/ValidationErrorResponse';
import { Result } from '../models/Result';
import { Service } from '../models/services/Service';
import { ServiceListByOrgIdRequest } from '../models/ports/requests/PortListByOrgIdRequest';
import { OrganizationsListResponse } from '../models/organizations/OrganizationsListResponse';
import { BusinessUnit } from '../models/organizations/BusinessUnit';
import { CriticalityTier } from '../models/organizations/CriticalityTier';
import { EnvironmentType } from '../models/organizations/EnvironmentType';
import { SubmitFeedbackRequest } from '../models/feedback/SubmitFeedbackRequest';
import { GetUsersForOrganizationRequest } from '../models/users/requests/GetUsersForOrganizationRequest';
import { User } from '../models/users/User';
import { GetRolesForOrganizationRequest } from '../models/users/requests/GetRolesForOrganizationRequest';
import { Role } from '../models/users/Role';
import { InviteUserRequest } from '../models/users/requests/InviteUserRequest';
import { AddUserToRoleRequest } from '../models/users/requests/AddUserToRoleRequest';
import { RemoveUserFromRoleRequest } from '../models/users/requests/RemoveUserFromRoleRequest';
import { RemoveUserRequest } from '../models/users/requests/RemoveUserRequest';
import { GetUserByIdRequest } from '../models/users/requests/GetUserByIdRequest';
import { RoleAssignment } from '../models/users/RoleAssignment';
import { CreateMicrosoftCustomerPlatformRequest } from '../models/platforms/requests/CreateMicrosoftCustomerPlatformRequest';
import { GetOrgAdminConsentStatusRequest } from '../models/organizations/requests/GetOrgAdminConsentStatusRequest';
import { OrgAdminConsentStatus } from '../models/organizations/OrgAdminConsentStatus';
import { CompleteOrgAdminConsentRequest } from '../models/organizations/requests/CompleteOrgAdminConsentRequest';
import { UpdatePropertyRequest } from '../models/services/requests/UpdatePropertyRequest';
import { UpdatePropertyScopeRequest } from '../models/services/requests/UpdatePropertyScopeRequest';
import { GetOrgRoleActionsRequest } from '../models/security/requests/GetOrgRoleActionsRequest';
import { RoleActions } from '../models/security/RoleActions';
import { Action } from '../models/security/Action';
import { GetOrgAvailableActionsRequest } from '../models/security/requests/GetOrgAvailableActionsRequest';
import { useLogging } from './LoggingProvider';
import { ValidateUniqueServiceBusinessKeyRequest } from '../models/services/requests/ValidateUniqueServiceBusinessKeyRequest';
import { ValidateUniqueProductBusinessKeyRequest } from '../models/products/requests/ValidateUniqueProductBusinessKeyRequest';
import { ValidateUniqueProductShortNameRequest } from '../models/products/requests/ValidateUniqueProductShortNameRequest';
import { ValidateUniqueServiceShortNameRequest } from '../models/services/requests/ValidateUniqueServiceShortNameRequest';

import { AuthenticationRedirectInProgressError, useAuthenticatedAccount } from './AuthenticatedAccountProvider';
import { ShortcutListRequest } from '../models/shortcuts/requests/ShortcutListRequest';
import { Shortcut } from '../models/shortcuts/Shortcut';
import { GetFederatedUserByQueryRequest } from '../models/federation/users/requests/GetFederatedUserByQueryRequest';
import { FederatedUser } from '../models/federation/users/FederatedUser';
import { ExecuteJourneyRequest } from '../models/journeys/requests/ExecuteJourneyRequest';
import { ExecuteJourneyResponse } from '../models/journeys/ExecuteJourneyResponse';

type ProviderProps = {
    children?: ReactNode;
};

type BackendServiceContextType = {
    sendFeedback: (props: SubmitFeedbackRequest, supressFailAlert?: boolean) => Promise<any>;
    getOrganizations: (supressFailAlert?: boolean) => Promise<OrganizationsListResponse>;
    getOrganizationById: (props: GetOrganizationByIdRequest, supressFailAlert?: boolean) => Promise<Organization>;
    newOrganization: (props: CreateOrganizationRequest, supressFailAlert?: boolean) => Promise<Organization>;
    draftSubscription: (
        organizationName: string,
        productSku: string,
        numberOfSeats: number,
        supressFailAlert?: boolean,
    ) => Promise<{ clientSecret: string }>;
    getShortcuts: (props: ShortcutListRequest, supressFailAlert?: boolean) => Promise<Shortcut[]>;
    getProducts: (props: ProductListRequest, supressFailAlert?: boolean) => Promise<ProductListResponse>;
    getProductById: (props: GetProductByIdRequest, supressFailAlert?: boolean) => Promise<GetProductByIdResponse>;
    validateProductBusinessKeyIsUnique: (
        props: ValidateUniqueProductBusinessKeyRequest,
        supressFailAlert?: boolean,
    ) => Promise<boolean>;
    validateProductShortNameIsUnique: (
        props: ValidateUniqueProductShortNameRequest,
        supressFailAlert?: boolean,
    ) => Promise<boolean>;
    enableProductRole: (
        props: EnableProductRoleRequest,
        supressFailAlert?: boolean,
    ) => Promise<EnableProductRoleResponse>;
    getPlatformById: (props: GetPlatformByIdRequest, supressFailAlert?: boolean) => Promise<Platform>;
    getPlatforms: (props: PlatformListRequest, supressFailAlert?: boolean) => Promise<Platform[]>;
    updateTenantIdForAzureAdPlatform: (
        props: UpdateTenantIdForAzureAdPlatformRequest,
        supressFailAlert?: boolean,
    ) => Promise<Platform>;
    newAzureRmPlatform: (props: CreateAzureRmPlatformRequest, supressFailAlert?: boolean) => Promise<Platform>;
    newGitHubPlatform: (props: CreateGitHubPlatformRequest, supressFailAlert?: boolean) => Promise<Platform>;
    newMicrosoftCustomerPlatform: (
        props: CreateMicrosoftCustomerPlatformRequest,
        supressFailAlert?: boolean,
    ) => Promise<Platform>;
    getEnvironmentTypeById: (
        props: GetEnvironmentTypeByIdRequest,
        supressFailAlert?: boolean,
    ) => Promise<EnvironmentType>;
    newEnvironmentType: (props: CreateEnvironmentTypeRequest, supressFailAlert?: boolean) => Promise<EnvironmentType>;
    updateEnvironmentType: (
        props: UpdateEnvironmentTypeRequest,
        supressFailAlert?: boolean,
    ) => Promise<EnvironmentType>;
    getCriticalityTierById: (
        props: GetCriticalityTierByIdRequest,
        supressFailAlert?: boolean,
    ) => Promise<CriticalityTier>;
    newCriticalityTier: (props: CreateCriticalityTierRequest, supressFailAlert?: boolean) => Promise<CriticalityTier>;
    updateCriticalityTier: (
        props: UpdateCriticalityTierRequest,
        supressFailAlert?: boolean,
    ) => Promise<CriticalityTier>;
    getBusinessUnitById: (props: GetBusinessUnitByIdRequest, supressFailAlert?: boolean) => Promise<BusinessUnit>;
    newBusinessUnit: (props: CreateBusinessUnitRequest, supressFailAlert?: boolean) => Promise<BusinessUnit>;
    updateBusinessUnit: (props: UpdateBusinessUnitRequest, supressFailAlert?: boolean) => Promise<BusinessUnit>;
    getProductRoleById: (props: GetProductRoleByIdRequest, supressFailAlert?: boolean) => Promise<ProductRole>;
    newProductRole: (props: CreateProductRoleRequest, supressFailAlert?: boolean) => Promise<ProductRole>;
    updateProductRole: (props: UpdateProductRoleRequest, supressFailAlert?: boolean) => Promise<ProductRole>;
    validateServiceBusinessKeyIsUnique: (
        props: ValidateUniqueServiceBusinessKeyRequest,
        supressFailAlert?: boolean,
    ) => Promise<boolean>;
    validateServiceShortNameIsUnique: (
        props: ValidateUniqueServiceShortNameRequest,
        supressFailAlert?: boolean,
    ) => Promise<boolean>;
    getDashboardData: (props: GetDashboardRequest, supressFailAlert?: boolean) => Promise<any>;
    getUsersByOrgId: (props: GetUsersForOrganizationRequest, supressFailAlert?: boolean) => Promise<User[]>;
    getRolesByOrgId: (props: GetRolesForOrganizationRequest, supressFailAlert?: boolean) => Promise<Role[]>;
    inviteUser: (props: InviteUserRequest, supressFailAlert?: boolean) => Promise<User>;
    addUserToRole: (props: AddUserToRoleRequest, supressFailAlert?: boolean) => Promise<RoleAssignment>;
    removeUserFromRole: (props: RemoveUserFromRoleRequest, supressFailAlert?: boolean) => Promise<void>;
    removeUser: (props: RemoveUserRequest, supressFailAlert?: boolean) => Promise<void>;
    getUserById: (props: GetUserByIdRequest, supressFailAlert?: boolean) => Promise<User>;
    getOrgAdminConsentStatus: (
        props: GetOrgAdminConsentStatusRequest,
        supressFailAlert?: boolean,
    ) => Promise<OrgAdminConsentStatus>;
    getOrgRoleActions: (props: GetOrgRoleActionsRequest, supressFailAlert?: boolean) => Promise<RoleActions[]>;
    getOrgAvailableActions: (props: GetOrgAvailableActionsRequest, supressFailAlert?: boolean) => Promise<Action[]>;
    completeOrgAdminConsent: (props: CompleteOrgAdminConsentRequest, supressFailAlert?: boolean) => Promise<void>;
    getFederatedUsersByQuery: (
        props: GetFederatedUserByQueryRequest,
        supressFailAlert?: boolean,
    ) => Promise<FederatedUser[]>;
};

const BackendServiceContext = createContext<BackendServiceContextType | undefined>(undefined);

export function BackendServiceProvider({ children }: ProviderProps) {
    const { enqueueSnackbar } = useSnackbar();
    const { trackTrace, trackException } = useLogging();
    const { backendApi } = useAuthenticatedAccount();

    // eslint-disable-next-line @typescript-eslint/comma-dangle
    const errorHandlerWrapper = async <TRes,>(
        axiosRequest: () => Promise<AxiosResponse<TRes>>,
        supressFailAlert: boolean = false,
    ) => {
        try {
            const response = await axiosRequest();
            return response.data;
        } catch (err: any) {
            if (err.response && err.response.status > 0) {
                trackException(err);
                if (!supressFailAlert) {
                    if (err.response.status === 400 && typeof err.response.data === 'object') {
                        enqueueSnackbar(
                            `${err.response.statusText || 'Unable to complete the request'}
                            ${
                                err.response.data &&
                                (err.response as BadRequestResponse).data
                                    ?.map((validationError) => `• ${validationError.errorMessage}`)
                                    .join('\n')
                            }`,
                            {
                                variant: 'error',
                                style: { whiteSpace: 'pre-line' },
                            },
                        );
                    } else if (typeof err.response.data === 'object') {
                        enqueueSnackbar(
                            `${err.response.statusText || 'Unable to complete the request'}
                            ${
                                err.response.data &&
                                ((err.response.data.messages &&
                                    (err.response as any).data.messages
                                        .map((messages: any) => `• ${messages}`)
                                        .join('\n')) ||
                                    `• ${err.response.data.status}`)
                            }`,
                            {
                                variant: 'error',
                                style: { whiteSpace: 'pre-line' },
                            },
                        );
                    } else {
                        enqueueSnackbar(
                            `${err.response.statusText || 'Unable to complete the request'}\n• ${err.response.data}`,
                            {
                                variant: 'error',
                                style: { whiteSpace: 'pre-line' },
                            },
                        );
                    }
                } else {
                    return err.response;
                }
            } else if (err instanceof AuthenticationRedirectInProgressError) {
                trackTrace(`BackendService suppressing error: ${err.message}`);
                return err.message;
            } else {
                trackException(err);
                if (!supressFailAlert) {
                    enqueueSnackbar(`Unable to complete the request\n• ${err.message}`, { variant: 'error' });
                }
            }
            throw new Error(err);
        }
    };

    // eslint-disable-next-line @typescript-eslint/comma-dangle
    const get = async <TRes,>(url: string, supressFailAlert: boolean = false): Promise<TRes> => {
        const res = await errorHandlerWrapper<Result<TRes>>(async () => {
            const innerRes = await backendApi.get(url);
            return innerRes;
        }, supressFailAlert);
        return res.value;
    };

    // eslint-disable-next-line @typescript-eslint/comma-dangle
    const post = async <TRes,>(url: string, data?: object, supressFailAlert: boolean = false): Promise<TRes> => {
        const res = await errorHandlerWrapper<Result<TRes>>(async () => {
            const innerRes = await backendApi.post<Result<TRes>>(url, data);
            return innerRes;
        }, supressFailAlert);
        return res.value;
    };

    // eslint-disable-next-line @typescript-eslint/comma-dangle
    const put = async <TRes,>(url: string, data?: object, supressFailAlert: boolean = false): Promise<TRes> => {
        const res = await errorHandlerWrapper<Result<TRes>>(async () => {
            const innerRes = await backendApi.put<Result<TRes>>(url, data);
            return innerRes;
        }, supressFailAlert);
        return res.value;
    };

    // eslint-disable-next-line @typescript-eslint/comma-dangle
    const del = async <TRes,>(url: string, data?: object, supressFailAlert: boolean = false): Promise<TRes> => {
        const res = await errorHandlerWrapper<Result<TRes>>(async () => {
            const innerRes = await backendApi.delete<Result<TRes>>(url, data);
            return innerRes;
        }, supressFailAlert);
        return res;
    };

    const executeJourney = async (props: ExecuteJourneyRequest, supressFailAlert: boolean = false) => {
        const res = await post<any>(`/organizations/${props.organizationId}/journeys`, props, supressFailAlert);
        return res;
    };

    const sendFeedback = async (props: SubmitFeedbackRequest, supressFailAlert: boolean = false) => {
        const res = await post<any>('/feedback', props, supressFailAlert);
        return res;
    };

    const getOrganizations = async (supressFailAlert: boolean = false) => {
        const res = await get<OrganizationsListResponse>('/platform/users/current', supressFailAlert);
        return res;
    };

    const getOrganizationById = async (props: GetOrganizationByIdRequest, supressFailAlert: boolean = false) => {
        const res = await get<Organization>(`/organizations/${props.organizationId}`, supressFailAlert);
        return res;
    };

    const newOrganization = async (props: CreateOrganizationRequest, supressFailAlert: boolean = false) => {
        const res = await post<Organization>('/organizations', props, supressFailAlert);
        return res;
    };

    const draftSubscription = async (
        organizationId: string,
        productSku: string,
        numberOfSeats: number,
        supressFailAlert: boolean = false,
    ): Promise<{ clientSecret: string }> => {
        // const res = await post<any>(
        //     `/organizations/${organizationId}/subscriptions`,
        //     {
        //         productSku,
        //         numberOfSeats,
        //     },
        //     supressFailAlert,
        // );
        // return res;

        // commented above and replaced to remove implementation details from bundle
        return Promise.resolve({ clientSecret: '' });
    };

    const getShortcuts = async (props: ShortcutListRequest, supressFailAlert: boolean = false) => {
        const res = await get<Shortcut[]>(`/organizations/${props.organizationId}/shortcuts`, supressFailAlert);
        return res;
    };

    const getProducts = async (props: ProductListRequest, supressFailAlert: boolean = false) => {
        const res = await get<ProductListResponse>(`/organizations/${props.organizationId}/products`, supressFailAlert);
        return res;
    };

    const getProductById = async (props: GetProductByIdRequest, supressFailAlert: boolean = false) => {
        const res = await get<GetProductByIdResponse>(
            `/organizations/${props.organizationId}/products/${props.id}`,
            supressFailAlert,
        );
        return res;
    };

    const validateProductBusinessKeyIsUnique = async (
        props: ValidateUniqueProductBusinessKeyRequest,
        supressFailAlert: boolean = false,
    ) => {
        const res = await get<boolean>(
            `/organizations/${props.organizationId}/products/business-key/${props.businessKey}/unique`,
            supressFailAlert,
        );
        return res;
    };

    const validateProductShortNameIsUnique = async (
        props: ValidateUniqueProductShortNameRequest,
        supressFailAlert: boolean = false,
    ) => {
        const res = await get<boolean>(
            `/organizations/${props.organizationId}/products/short-name/${props.shortName}/unique`,
            supressFailAlert,
        );
        return res;
    };

    const enableProductRole = async (props: EnableProductRoleRequest, supressFailAlert: boolean = false) => {
        const res = await post<EnableProductRoleResponse>(
            `/organizations/${props.organizationId}/products/${props.productId}/product-roles/${props.productRoleId}`,
            props,
            supressFailAlert,
        );
        return res;
    };

    const getPlatformById = async (props: GetPlatformByIdRequest, supressFailAlert: boolean = false) => {
        const res = await get<Platform>(
            `/organizations/${props.organizationId}/platforms/${props.id}`,
            supressFailAlert,
        );
        return res;
    };

    const getPlatforms = async (props: PlatformListRequest, supressFailAlert: boolean = false) => {
        const res = await get<Platform[]>(`/organizations/${props.organizationId}/platforms`, supressFailAlert);
        return res;
    };

    const updateTenantIdForAzureAdPlatform = async (
        props: UpdateTenantIdForAzureAdPlatformRequest,
        supressFailAlert: boolean = false,
    ) => {
        const res = await put<Platform>(
            `/organizations/${props.organizationId}/platforms/azuread/tenantId`,
            props,
            supressFailAlert,
        );
        return res;
    };

    const newAzureRmPlatform = async (props: CreateAzureRmPlatformRequest, supressFailAlert: boolean = false) => {
        const res = await post<Platform>(
            `/organizations/${props.organizationId}/platforms/azurerm`,
            props,
            supressFailAlert,
        );
        return res;
    };

    const newGitHubPlatform = async (props: CreateGitHubPlatformRequest, supressFailAlert: boolean = false) => {
        const res = await post<Platform>(
            `/organizations/${props.organizationId}/platforms/github`,
            props,
            supressFailAlert,
        );
        return res;
    };

    const newMicrosoftCustomerPlatform = async (
        props: CreateMicrosoftCustomerPlatformRequest,
        supressFailAlert: boolean = false,
    ) => {
        const res = await post<Platform>(
            `/organizations/${props.organizationId}/platforms/microsoft-customer`,
            props,
            supressFailAlert,
        );
        return res;
    };

    const getEnvironmentTypeById = async (props: GetEnvironmentTypeByIdRequest, supressFailAlert: boolean = false) => {
        const res = await get<EnvironmentType>(
            `/organizations/${props.organizationId}/environment-types/${props.id}`,
            supressFailAlert,
        );
        return res;
    };

    const newEnvironmentType = async (props: CreateEnvironmentTypeRequest, supressFailAlert: boolean = false) => {
        const res = await post<EnvironmentType>(
            `/organizations/${props.organizationId}/environment-types`,
            props,
            supressFailAlert,
        );
        return res;
    };

    const updateEnvironmentType = async (props: UpdateEnvironmentTypeRequest, supressFailAlert: boolean = false) => {
        const res = await put<EnvironmentType>(
            `/organizations/${props.organizationId}/environment-types/${props.id}`,
            props,
            supressFailAlert,
        );
        return res;
    };

    const getCriticalityTierById = async (props: GetCriticalityTierByIdRequest, supressFailAlert: boolean = false) => {
        const res = await get<CriticalityTier>(
            `/organizations/${props.organizationId}/criticality-tiers/${props.id}`,
            supressFailAlert,
        );
        return res;
    };

    const newCriticalityTier = async (props: CreateCriticalityTierRequest, supressFailAlert: boolean = false) => {
        const res = await post<CriticalityTier>(
            `/organizations/${props.organizationId}/criticality-tiers`,
            props,
            supressFailAlert,
        );
        return res;
    };

    const updateCriticalityTier = async (props: UpdateCriticalityTierRequest, supressFailAlert: boolean = false) => {
        const res = await put<CriticalityTier>(
            `/organizations/${props.organizationId}/criticality-tiers/${props.id}`,
            props,
            supressFailAlert,
        );
        return res;
    };

    const getBusinessUnitById = async (props: GetBusinessUnitByIdRequest, supressFailAlert: boolean = false) => {
        const res = await get<BusinessUnit>(
            `/organizations/${props.organizationId}/business-units/${props.id}`,
            supressFailAlert,
        );
        return res;
    };

    const newBusinessUnit = async (props: CreateBusinessUnitRequest, supressFailAlert: boolean = false) => {
        const res = await post<BusinessUnit>(
            `/organizations/${props.organizationId}/business-units`,
            props,
            supressFailAlert,
        );
        return res;
    };

    const updateBusinessUnit = async (props: UpdateBusinessUnitRequest, supressFailAlert: boolean = false) => {
        const res = await put<BusinessUnit>(
            `/organizations/${props.organizationId}/business-units/${props.id}`,
            props,
            supressFailAlert,
        );
        return res;
    };

    const getProductRoleById = async (props: GetProductRoleByIdRequest, supressFailAlert: boolean = false) => {
        const res = await get<ProductRole>(
            `/organizations/${props.organizationId}/product-roles/${props.id}`,
            supressFailAlert,
        );
        return res;
    };

    const newProductRole = async (props: CreateProductRoleRequest, supressFailAlert: boolean = false) => {
        const res = await post<ProductRole>(
            `/organizations/${props.organizationId}/product-roles`,
            props,
            supressFailAlert,
        );
        return res;
    };

    const updateProductRole = async (props: UpdateProductRoleRequest, supressFailAlert: boolean = false) => {
        const res = await put<ProductRole>(
            `/organizations/${props.organizationId}/product-roles/${props.id}`,
            props,
            supressFailAlert,
        );
        return res;
    };

    const validateServiceBusinessKeyIsUnique = async (
        props: ValidateUniqueServiceBusinessKeyRequest,
        supressFailAlert: boolean = false,
    ) => {
        const res = await get<boolean>(
            `/organizations/${props.organizationId}/products/${props.productId}/services/business-key/${props.businessKey}/unique`,
            supressFailAlert,
        );
        return res;
    };

    const validateServiceShortNameIsUnique = async (
        props: ValidateUniqueServiceShortNameRequest,
        supressFailAlert: boolean = false,
    ) => {
        const res = await get<boolean>(
            `/organizations/${props.organizationId}/products/${props.productId}/services/short-name/${props.shortName}/unique`,
            supressFailAlert,
        );
        return res;
    };

    const servicesListByOrgId = async (props: ServiceListByOrgIdRequest, supressFailAlert: boolean = false) => {
        const res = await get<Service[]>(
            `/organizations/${props.organizationId}/products/*/services/`,
            supressFailAlert,
        );
        return res;
    };

    const updateProperty = async (props: UpdatePropertyRequest, supressFailAlert: boolean = false) => {
        const res = await post<void>(
            `/organizations/${props.organizationId}/products/${props.productId}/services/${props.serviceId}/property`,
            props,
            supressFailAlert,
        );
        return res;
    };

    const updatePropertyScope = async (props: UpdatePropertyScopeRequest, supressFailAlert: boolean = false) => {
        const res = await post<void>(
            `/organizations/${props.organizationId}/products/${props.productId}/services/${props.serviceId}/property-scope`,
            props,
            supressFailAlert,
        );
        return res;
    };

    const getUsersByOrgId = async (props: GetUsersForOrganizationRequest, supressFailAlert: boolean = false) => {
        const res = await get<User[]>(`/organizations/${props.organizationId}/users`, supressFailAlert);
        return res;
    };

    const getRolesByOrgId = async (props: GetRolesForOrganizationRequest, supressFailAlert: boolean = false) => {
        const res = await get<Role[]>(`/organizations/${props.organizationId}/roles`, supressFailAlert);
        return res;
    };

    const inviteUser = async (props: InviteUserRequest, supressFailAlert: boolean = false) => {
        const res = await post<User>(`/organizations/${props.organizationId}/users/invite`, props, supressFailAlert);
        return res;
    };

    const addUserToRole = async (props: AddUserToRoleRequest, supressFailAlert: boolean = false) => {
        const res = await post<RoleAssignment>(
            `/organizations/${props.organizationId}/role-assignments`,
            props,
            supressFailAlert,
        );
        return res;
    };

    const removeUserFromRole = async (props: RemoveUserFromRoleRequest, supressFailAlert: boolean = false) => {
        const res = await del<void>(
            `/organizations/${props.organizationId}/role-assignments/${props.roleAssignmentId}`,
            props,
            supressFailAlert,
        );
        return res;
    };

    const removeUser = async (props: RemoveUserRequest, supressFailAlert: boolean = false) => {
        const res = await del<void>(
            `/organizations/${props.organizationId}/users/${props.id}`,
            props,
            supressFailAlert,
        );
        return res;
    };

    const getUserById = async (props: GetUserByIdRequest, supressFailAlert: boolean = false) => {
        const res = await get<User>(`/organizations/${props.organizationId}/users/${props.id}`, supressFailAlert);
        return res;
    };

    const getOrgAdminConsentStatus = async (
        props: GetOrgAdminConsentStatusRequest,
        supressFailAlert: boolean = false,
    ) => {
        const res = await get<OrgAdminConsentStatus>(
            `/organizations/${props.organizationId}/directory/admin-consent-status`,
            supressFailAlert,
        );
        return res;
    };

    const completeOrgAdminConsent = async (
        props: CompleteOrgAdminConsentRequest,
        supressFailAlert: boolean = false,
    ) => {
        const res = await post<void>(
            `/organizations/${props.organizationId}/directory/admin-consent`,
            props,
            supressFailAlert,
        );
        return res;
    };

    const getFederatedUsersByQuery = async (
        props: GetFederatedUserByQueryRequest,
        supressFailAlert: boolean = false,
    ) => {
        const res = await get<FederatedUser[]>(
            `/organizations/${props.organizationId}/federation/users/search/${encodeURIComponent(props.query)}`,
            supressFailAlert,
        );
        return res;
    };

    const getOrgRoleActions = async (props: GetOrgRoleActionsRequest, supressFailAlert: boolean = false) => {
        const tempRoles = [
            'Architect',
            'Developer',
            'Product Owner',
            'Product Manager',
            'Operations',
            'Quality Assurance',
            'Business Analyst',
            'Product Sponsor',
            'Designer',
            'Support',
            'Team Lead',
        ];

        const roleActions: RoleActions[] = [
            {
                role: 'Architect',
                assignedActions: [
                    {
                        value: 'Technology.Design.EditServices',
                        enabled: true,
                    },
                    {
                        value: 'Technology.Design.EnableServices',
                        enabled: true,
                        environments: ['dev', 'qa', 'uat', 'stg', 'prd'],
                    },
                    {
                        value: 'Reporting.Observe.CreateReports',
                        enabled: true,
                        environments: ['dev', 'qa', 'uat', 'stg', 'prd'],
                    },
                    {
                        value: 'Business.Configure.ManageOrganization',
                        enabled: true,
                    },
                    {
                        value: 'Technology.Build.ViewCode',
                        enabled: true,
                    },
                    {
                        value: 'Technology.Build.EditCode',
                        enabled: true,
                    },
                ],
            },
            {
                role: 'Developer',
                assignedActions: [
                    {
                        value: 'Technology.Design.EditServices',
                        enabled: true,
                    },
                    {
                        value: 'Technology.Design.EnableServices',
                        enabled: true,
                        environments: ['dev', 'qa'],
                    },
                    {
                        value: 'Reporting.Observe.CreateReports',
                        enabled: true,
                        environments: ['dev', 'qa', 'uat', 'stg', 'prd'],
                    },
                    {
                        value: 'Technology.Build.ViewCode',
                        enabled: true,
                    },
                    {
                        value: 'Technology.Build.EditCode',
                        enabled: true,
                    },
                ],
            },
            ...tempRoles
                .filter((tr) => tr !== 'Architect' && tr !== 'Developer')
                .map((tr) => {
                    return { role: tr, assignedActions: [] };
                }),
        ];
        return roleActions;
    };

    const getOrgAvailableActions = async (props: GetOrgAvailableActionsRequest, supressFailAlert: boolean = false) => {
        const actions: Action[] = [
            {
                category: 'Design',
                friendlyName: 'Edit Services',
                value: 'Technology.Design.EditServices',
                environmentAgnostic: true,
            },
            {
                category: 'Design',
                friendlyName: 'Enable Services',
                value: 'Technology.Design.EnableServices',
                environmentAgnostic: false,
            },
            {
                category: 'Build',
                friendlyName: 'View Code',
                value: 'Technology.Build.ViewCode',
                environmentAgnostic: true,
            },
            {
                category: 'Build',
                friendlyName: 'Edit Code',
                value: 'Technology.Build.EditCode',
                environmentAgnostic: true,
            },
            {
                category: 'Observe',
                friendlyName: 'Create Reports',
                value: 'Reporting.Observe.CreateReports',
                environmentAgnostic: false,
            },
            {
                category: 'Observe',
                friendlyName: 'View Reports',
                value: 'Reporting.Observe.ViewReports',
                environmentAgnostic: false,
            },
            {
                category: 'Observe',
                friendlyName: 'View Deployments',
                value: 'Technology.Observe.ViewDeployments',
                environmentAgnostic: false,
            },
            {
                category: 'Inspect',
                friendlyName: 'View Application Logs',
                value: 'Technology.Inspect.ViewLogs',
                environmentAgnostic: false,
            },
            {
                category: 'Act',
                friendlyName: 'Create Alerts',
                value: 'Technology.Act.CreateAlerts',
                environmentAgnostic: false,
            },
            {
                category: 'Configure',
                friendlyName: 'Manage Organization',
                value: 'Business.Configure.ManageOrganization',
                environmentAgnostic: true,
            },
            {
                category: 'Configure',
                friendlyName: 'Manage Permissions',
                value: 'Business.Configure.ManagePermissions',
                environmentAgnostic: true,
            },
        ];

        return actions;
    };

    const getDashboardData = async (props: GetDashboardRequest, supressFailAlert: boolean = false) => {
        // const res = await get<GetDashboardResponse>(
        //     `/organizations/${props.organizationId}/dashboard`,
        //     supressFailAlert,
        // );
        // return res;
        return {
            nodes: [
                {
                    id: '1df829f0-7826-422a-bd8a-adb4c26f4fe9',
                    name: 'Shared Services',
                    group: 1,
                    type: 'Product',
                    services: {
                        nodes: [
                            {
                                id: '1df829f0-7826-422a-bd8a-adb4c26f4fe9',
                                name: 'Product Management',
                                group: 1,
                                type: 'Service',
                            },
                            {
                                id: '48850d5b-cfe3-40ae-97e7-5b7fceb996df',
                                name: 'Shared Kubernetes Cluster',
                                group: 2,
                                type: 'Service',
                            },
                            {
                                id: 'e2df6c9c-a730-49f8-9891-3e570cd7a808',
                                name: 'Shared Container Registry',
                                group: 3,
                                type: 'Service',
                            },
                            {
                                id: 'e2df6c9c-a730-49f8-9891-3e570cd7a809',
                                name: 'Shared Eventhub',
                                group: 4,
                                type: 'Service',
                            },
                            // {
                            //     id: '5e0c3451-c7fa-4d3b-ab2b-7506aa88afde',
                            //     name: 'Shared FD',
                            //     group: 5,
                            //     type: 'Service',
                            // },
                            {
                                id: 'a3f7bdfb-caba-4b93-bafd-57808048ac48',
                                name: 'Shared Data Factory',
                                group: 6,
                                type: 'Service',
                            },
                        ],
                        links: [
                            {
                                name: 'Product Management - AKS',
                                source: '1df829f0-7826-422a-bd8a-adb4c26f4fe9',
                                target: '48850d5b-cfe3-40ae-97e7-5b7fceb996df',
                                type: 'parent',
                            },
                            {
                                name: 'Product Management - ACR',
                                source: '1df829f0-7826-422a-bd8a-adb4c26f4fe9',
                                target: 'e2df6c9c-a730-49f8-9891-3e570cd7a808',
                                type: 'parent',
                            },
                            {
                                name: 'Product Management - EVH',
                                source: '1df829f0-7826-422a-bd8a-adb4c26f4fe9',
                                target: 'e2df6c9c-a730-49f8-9891-3e570cd7a809',
                                type: 'parent',
                            },
                            // {
                            //     name: 'Product Management - FD',
                            //     source: '1df829f0-7826-422a-bd8a-adb4c26f4fe9',
                            //     target: '5e0c3451-c7fa-4d3b-ab2b-7506aa88afde',
                            //     type: 'parent',
                            // },
                            {
                                name: 'Product Management - ADF',
                                source: '1df829f0-7826-422a-bd8a-adb4c26f4fe9',
                                target: 'a3f7bdfb-caba-4b93-bafd-57808048ac48',
                                type: 'parent',
                            },
                        ],
                    },
                },
                {
                    id: '48850d5b-cfe3-40ae-97e7-5b7fceb996df',
                    name: 'Vendor Data Feeds',
                    group: 2,
                    type: 'Product',
                },
                {
                    id: 'e2df6c9c-a730-49f8-9891-3e570cd7a808',
                    name: 'Epic',
                    group: 3,
                    type: 'Product',
                },
                {
                    id: 'e2df6c9c-a730-49f8-9891-3e570cd7a809',
                    name: 'Public Website',
                    group: 5,
                    type: 'Product',
                },
                {
                    id: '5e0c3451-c7fa-4d3b-ab2b-7506aa88afde',
                    name: 'Patient Scheduling',
                    group: 4,
                    type: 'Product',
                },
            ],
            links: [
                {
                    name: 'Shared Services - Source Data Node',
                    source: '1df829f0-7826-422a-bd8a-adb4c26f4fe9',
                    target: '48850d5b-cfe3-40ae-97e7-5b7fceb996df',
                    type: 'parent',
                },
                {
                    name: 'Shared Services - Backend Node App',
                    source: '1df829f0-7826-422a-bd8a-adb4c26f4fe9',
                    target: 'e2df6c9c-a730-49f8-9891-3e570cd7a808',
                    type: 'parent',
                },
                {
                    name: 'Shared Services - Backend Node App2',
                    source: '1df829f0-7826-422a-bd8a-adb4c26f4fe9',
                    target: 'e2df6c9c-a730-49f8-9891-3e570cd7a809',
                    type: 'parent',
                },
                {
                    name: 'Shared Services - Frontend React App',
                    source: '1df829f0-7826-422a-bd8a-adb4c26f4fe9',
                    target: '5e0c3451-c7fa-4d3b-ab2b-7506aa88afde',
                    type: 'parent',
                },
                {
                    name: 'Backend Node App - Frontend React App',
                    source: 'e2df6c9c-a730-49f8-9891-3e570cd7a808',
                    target: '5e0c3451-c7fa-4d3b-ab2b-7506aa88afde',
                    type: 'link',
                },
                {
                    name: 'Backend Node App2 - Frontend React App',
                    source: 'e2df6c9c-a730-49f8-9891-3e570cd7a809',
                    target: '5e0c3451-c7fa-4d3b-ab2b-7506aa88afde',
                    type: 'link',
                },
            ],
        } as GetDashboardResponse;
    };

    const memoValue = useMemo(
        () => ({
            sendFeedback,
            getOrganizations,
            getOrganizationById,
            newOrganization,
            draftSubscription,
            getShortcuts,
            getProducts,
            getProductById,
            validateProductBusinessKeyIsUnique,
            validateProductShortNameIsUnique,
            enableProductRole,
            getPlatformById,
            getPlatforms,
            updateTenantIdForAzureAdPlatform,
            newAzureRmPlatform,
            newGitHubPlatform,
            newMicrosoftCustomerPlatform,
            getEnvironmentTypeById,
            newEnvironmentType,
            updateEnvironmentType,
            getCriticalityTierById,
            newCriticalityTier,
            updateCriticalityTier,
            getBusinessUnitById,
            newBusinessUnit,
            updateBusinessUnit,
            getProductRoleById,
            newProductRole,
            updateProductRole,
            validateServiceBusinessKeyIsUnique,
            validateServiceShortNameIsUnique,
            servicesListByOrgId,
            updateProperty,
            updatePropertyScope,
            getUsersByOrgId,
            getRolesByOrgId,
            inviteUser,
            addUserToRole,
            removeUserFromRole,
            removeUser,
            getUserById,
            getOrgAdminConsentStatus,
            completeOrgAdminConsent,
            getOrgRoleActions,
            getOrgAvailableActions,
            getDashboardData,
            getFederatedUsersByQuery,
        }),
        [backendApi],
    );

    return <BackendServiceContext.Provider value={memoValue}>{children}</BackendServiceContext.Provider>;
}

export const useBackendService = (): BackendServiceContextType => {
    const context = useContext(BackendServiceContext);
    if (!context) {
        throw new Error('useBackendService must be used within a Backend Service Provider');
    }
    return context;
};
