import { Formik, Form, FormikProps, FormikValues } from 'formik';
import { debounce } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Container, Grid, Typography } from '@mui/material';
import { TextField } from '../../../components/elements/input/TextField';
import { SubmitButton } from '../../../components/elements/input/SubmitButton';
import { useBackendService } from '../../../providers/BackendServiceProvider';
import { Select } from '../../../components/elements/input/Select';
import { CreateProductRequest } from '../../../models/products/CreateProductRequest';
import { CriticalityTier } from '../../../models/organizations/CriticalityTier';
import { BusinessUnit } from '../../../models/organizations/BusinessUnit';
import { DrawerFooterAction, useLayout } from '../../../providers/LayoutProvider';
import { useLogging } from '../../../providers/LoggingProvider';
import { stringSequentialValidation } from '../../../utils/validation/StringSequentialValidation';
import { useProductNewMutation, useProductUpdateMutation } from '../../../features/products/productsApiSlice';

const FormikWrapped = styled(Formik)(({ theme }) => ({
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
}));

const AUDIENCE_OPTIONS = [
    { label: 'N/A', value: 'N/A' },
    { label: 'Internal employees in office only', value: 'INNETWORK' },
    { label: 'Employees and contractors', value: 'TENANTUSERS' },
    { label: 'Org users and outside partners/vendors', value: 'TENANTANDGUESTUSERS' },
    { label: 'Any authenticated users', value: 'AUTHENTICATEDUSERS' },
    { label: 'Publically available without accounts', value: 'PUBLIC' },
];

const EXPECTED_VISITOR_QUANTITY_OPTIONS = [
    { label: 'N/A', value: 'N/A' },
    { label: 'Less than 100', value: '<100' },
    { label: 'Between 100 and 1,000', value: '100-1000' },
    { label: 'Between 1,000 and 10,000', value: '1000-10000' },
    { label: 'Between 10,000 and 100,000', value: '10000-100000' },
    { label: 'Between 100,000 and 1,000,000', value: '100000-1000000' },
    { label: 'Greater than 1,000,000', value: '>1000000' },
];

const EXPECTED_VISITOR_FREQUENCY_OPTIONS = [
    { label: 'N/A', value: 'N/A' },
    { label: 'Hourly', value: 'HOURLY' },
    { label: 'Daily', value: 'DAILY' },
    { label: 'Weekly', value: 'WEEKLY' },
    { label: 'Monthly', value: 'MONTHLY' },
    { label: 'Quarterly', value: 'QUARTERLY' },
    { label: 'Infrequent', value: 'INFREQUENT' },
];

const EXPECTED_VISITOR_DISTRIBUTION_OPTIONS = [
    { label: 'N/A', value: 'N/A' },
    { label: 'Anytime', value: 'ANYTIME' },
    { label: 'Local working hours', value: 'WORKINGHOURS' },
    { label: 'Focused heavily at particualar times', value: 'SPECIFICTIMES' },
    { label: 'Focused heavily around events', value: 'SPECIFICEVENTS' },
];

type Props = {
    criticalityTiers: CriticalityTier[];
    businessUnits: BusinessUnit[];
    organizationId: string;
    existingProductId?: string;
    actionCompleteCallback?: (productId: string) => void;
};

export function ManageProduct({
    criticalityTiers,
    businessUnits,
    organizationId,
    existingProductId,
    actionCompleteCallback,
}: Props) {
    const INITIAL_FORM_STATE: Partial<CreateProductRequest> = {
        name: '',
        shortName: '',
        businessKey: '',
        description: '',
        audience: 'N/A',
        businessUnitBusinessKey: '',
        criticalityTier: '',
        expectedUniqueVisitorQuantity: 'N/A',
        expectedUniqueVisitorFrequency: 'N/A',
        expectedUniqueVisitorDistribution: 'N/A',
    };

    const navigate = useNavigate();
    const [initialFormState, setInitialFormState] = useState(INITIAL_FORM_STATE);
    const { enqueueSnackbar } = useSnackbar();
    const { getProductById, validateProductBusinessKeyIsUnique, validateProductShortNameIsUnique } =
        useBackendService();
    const [newProduct] = useProductNewMutation();
    const [updateProduct] = useProductUpdateMutation();
    const { isPreviewFeatureVisible, setRightContextDrawerActions } = useLayout();
    const { trackTrace } = useLogging();
    const formRef = useRef<FormikProps<FormikValues>>(null);

    // TODO: breakout axiosInstance / API client outside of backend provider so it can be used outside the context of a component
    const FORM_VALIDATION = Yup.object().shape({
        name: Yup.string().required('Product Name is required'),
        shortName: stringSequentialValidation([
            Yup.string().max(30, 'Product Short Name must be 30 characters or less'),
            Yup.string().matches(/^[a-z0-9]*$/, 'Product Short Name can only contain lowercase letters and numbers'),
            Yup.string().test(
                'is-unique',
                'Product Short Name must be unique for this Organization',
                (value: string | undefined) => {
                    if (existingProductId) {
                        // entity already exists, and this value is readonly. do not run this validation check
                        return true;
                    }

                    if (!value) {
                        // blank value is allowed as this is not a required field
                        return true;
                    }

                    return validateProductShortNameIsUnique(
                        {
                            organizationId,
                            shortName: value,
                        },
                        true,
                    );
                },
            ),
        ]),
        businessKey: stringSequentialValidation([
            Yup.string().required('Product Business Key is required'),
            Yup.string().max(4, 'Product Business Key must be 4 characters or less'),
            Yup.string().matches(/^[a-z0-9]*$/, 'Product Business Key can only contain lowercase letters and numbers'),
            Yup.string().test(
                'is-unique',
                'Product Business Key must be unique for this Organization',
                (value: string | undefined) => {
                    if (existingProductId) {
                        // entity already exists, and this value is readonly. do not run this validation check
                        return true;
                    }

                    if (!value) {
                        return false;
                    }

                    return validateProductBusinessKeyIsUnique(
                        {
                            organizationId,
                            businessKey: value,
                        },
                        true,
                    );
                },
            ),
        ]),
        description: Yup.string(),
        audience: Yup.string().required('Audience is required'),
        businessUnitBusinessKey: Yup.string().required('Business Unit is required'),
        criticalityTier: Yup.string().required('Criticality Tier is required'),
        expectedUniqueVisitorQuantity: Yup.string().required('Expected Number Of Unique Visitors is required'),
        expectedUniqueVisitorFrequency: Yup.string().required('Expected Unique Visitor Return Frequency is required'),
        expectedUniqueVisitorDistribution: Yup.string().required('Expected Unique Visitor Access Timing is required'),
    });

    const criticalityTierOptions = criticalityTiers.map((tier) => ({
        label: `${tier.businessKey.toUpperCase()} - ${tier.name}`,
        value: tier.id,
    }));

    const businessUnitOptions = businessUnits.map((unit) => ({
        label: unit.name,
        value: unit.businessUnitBusinessKey,
    }));

    const fetchData = async () => {
        trackTrace(`Loading existing product data to edit '${existingProductId}'`);
        const response = await getProductById({
            organizationId,
            id: existingProductId!,
        });
        setInitialFormState({
            name: response.name,
            shortName: response.shortName,
            businessKey: response.businessKey,
            description: response.description,
            audience: response.audience,
            businessUnitBusinessKey: response.businessUnitBusinessKey,
            criticalityTier: response.criticalityTier,
            expectedUniqueVisitorQuantity: response.expectedUniqueVisitorQuantity,
            expectedUniqueVisitorFrequency: response.expectedUniqueVisitorFrequency,
            expectedUniqueVisitorDistribution: response.expectedUniqueVisitorDistribution,
        });
    };

    useEffect(() => {
        if (existingProductId) {
            fetchData();
        }

        const actions: DrawerFooterAction[] = [
            {
                action: () => {
                    formRef.current?.submitForm();
                },
                actionText: existingProductId ? 'Save' : 'Create',
                actionDisabled: formRef.current?.isSubmitting,
            },
        ];

        setRightContextDrawerActions(actions);
    }, []);

    const handleSubmit = async (values: any, formik: any) => {
        let resId: string;

        if (existingProductId) {
            // Update existing product
            trackTrace(`Updating product  '${existingProductId}'..`);
            const prodRes = await updateProduct({
                organizationId,
                id: existingProductId!,
                ...values,
            }).unwrap();

            formik.setSubmitting(false);
            resId = prodRes.id;

            enqueueSnackbar('Product saved');
            if (actionCompleteCallback) {
                actionCompleteCallback(resId);
            }
        } else {
            // Create new product
            trackTrace('Creating new product..');
            const prodRes = await newProduct({
                ...values,
                // if short name is omitted, use the business key
                shortName: values.shortName ? values.shortName : values.businessKey,
                organizationId,
            } as CreateProductRequest).unwrap();

            formik.setSubmitting(false);
            resId = prodRes.id;

            enqueueSnackbar('Product created');
            if (actionCompleteCallback) {
                actionCompleteCallback(resId);
            }
        }
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Container maxWidth="md">
                    <FormikWrapped
                        innerRef={formRef}
                        initialValues={initialFormState}
                        enableReinitialize
                        validationSchema={FORM_VALIDATION}
                        validateOnChange={false}
                        validateOnBlur={false}
                        onSubmit={(values, formik) => handleSubmit(values, formik)}
                    >
                        {({ isSubmitting, values, validateForm }) => {
                            // debounce the validation of the entire form and execute any time the values change
                            const debouncedValidate = useMemo(() => debounce(validateForm, 400), [validateForm]);
                            useEffect(() => {
                                debouncedValidate(values);
                            }, [values, debouncedValidate]);

                            return (
                                <Form autoComplete="off">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="name"
                                                label="Product Name"
                                                required
                                                autoFocus={!existingProductId ? true : undefined}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="shortName"
                                                label="Product Short Name"
                                                disabled={!!existingProductId}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="businessKey"
                                                label="Product Business Key"
                                                disabled={!!existingProductId}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField name="description" label="Description" multiline rows="3" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Select
                                                name="businessUnitBusinessKey"
                                                label="Business Unit"
                                                options={businessUnitOptions}
                                                required
                                            />
                                        </Grid>
                                        {isPreviewFeatureVisible && (
                                            <Grid item xs={12}>
                                                <Select
                                                    name="audience"
                                                    label="Audience"
                                                    options={AUDIENCE_OPTIONS}
                                                    required
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <Select
                                                name="criticalityTier"
                                                label="Criticality Tier"
                                                options={criticalityTierOptions}
                                                required
                                            />
                                        </Grid>
                                        {isPreviewFeatureVisible && (
                                            <Grid item xs={12}>
                                                <Select
                                                    name="expectedUniqueVisitorQuantity"
                                                    label="Expected Number Of Unique Visitors"
                                                    options={EXPECTED_VISITOR_QUANTITY_OPTIONS}
                                                    required
                                                />
                                            </Grid>
                                        )}
                                        {isPreviewFeatureVisible && (
                                            <Grid item xs={12}>
                                                <Select
                                                    name="expectedUniqueVisitorFrequency"
                                                    label="Expected Unique Visitor Return Frequency"
                                                    options={EXPECTED_VISITOR_FREQUENCY_OPTIONS}
                                                    required
                                                />
                                            </Grid>
                                        )}
                                        {isPreviewFeatureVisible && (
                                            <Grid item xs={12}>
                                                <Select
                                                    name="expectedUniqueVisitorDistribution"
                                                    label="Expected Unique Visitor Access Timing"
                                                    options={EXPECTED_VISITOR_DISTRIBUTION_OPTIONS}
                                                    required
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Form>
                            );
                        }}
                    </FormikWrapped>
                </Container>
            </Grid>
        </Grid>
    );
}
