import { Container, Grid, Typography } from '@mui/material';
import * as Yup from 'yup';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import { Dispatch, memo, SetStateAction, useEffect, useRef, useState } from 'react';
import { ComboBox } from '../../../components/elements/input/ComboBox';
import { SSCard } from '../../../components/elements/SSCard';
import { stringSequentialValidation } from '../../../utils/validation/StringSequentialValidation';
import { FlowUtils } from '../../../utils/FlowUtils';
import { useEntityContext } from '../../../providers/EntityContextProvider';
import { TextField } from '../../../components/elements/input/TextField';
import { useOrganizationMetadataByIdQuery } from '../../organizations/organizationsApiSlice';
import { CreateProductRequest } from '../../../models/products/CreateProductRequest';
import { useBackendService } from '../../../providers/BackendServiceProvider';
import { useLayout } from '../../../providers/LayoutProvider';

type Props = {
    setIsValid: (isValid: boolean) => void;
    setValidate: Dispatch<SetStateAction<(() => boolean | Promise<boolean>) | undefined>>;
    createProductRequest: Partial<CreateProductRequest>;
    setCreateProductRequest: Dispatch<SetStateAction<Partial<CreateProductRequest>>>;
};

export default function NewProductStepDetails({
    setIsValid,
    setValidate,
    createProductRequest,
    setCreateProductRequest,
}: Props) {
    const [fieldFocusId, setFieldFocusId] = useState<number>(1);
    const { entityContext } = useEntityContext();
    const { isPlatformManagerMode } = useLayout();
    const formRef = useRef<FormikProps<FormikValues>>(null);
    const { validateProductBusinessKeyIsUnique, validateProductShortNameIsUnique } = useBackendService();
    const { data: organization } = useOrganizationMetadataByIdQuery({
        organizationId: entityContext.organizationSlug!,
    });

    const FORM_VALIDATION = Yup.object().shape({
        name: Yup.string().required('Product Name is required'),
        shortName: stringSequentialValidation([
            Yup.string().max(30, 'Product Short Name must be 30 characters or less'),
            Yup.string().matches(/^[a-z0-9]*$/, 'Product Short Name can only contain lowercase letters and numbers'),
            Yup.string().test(
                'is-unique',
                'Product Short Name must be unique for this Organization',
                (value: string | undefined) => {
                    if (!value) {
                        // blank value is allowed as this is not a required field
                        return true;
                    }

                    return FlowUtils.asyncDebounce(
                        () =>
                            validateProductShortNameIsUnique(
                                {
                                    organizationId: entityContext.organizationSlug!,
                                    shortName: value,
                                },
                                true,
                            ),
                        400,
                    )();
                },
            ),
        ]),
        businessKey: stringSequentialValidation([
            Yup.string().required('Product Business Key is required'),
            Yup.string().max(4, 'Product Business Key must be 4 characters or less'),
            Yup.string().matches(/^[a-z0-9]*$/, 'Product Business Key can only contain lowercase letters and numbers'),
            Yup.string().test(
                'is-unique',
                'Product Business Key must be unique for this Organization',
                (value: string | undefined) => {
                    if (!value) {
                        return false;
                    }

                    return FlowUtils.asyncDebounce(
                        () =>
                            validateProductBusinessKeyIsUnique(
                                {
                                    organizationId: entityContext.organizationSlug!,
                                    businessKey: value,
                                },
                                true,
                            ),
                        400,
                    )();
                },
            ),
        ]),
        description: Yup.string(),
        businessUnitBusinessKey: Yup.string().required('Business Unit is required'),
        criticalityTier: Yup.string().required('Criticality Tier is required'),
    });

    const validateForm = async () => {
        await formRef.current?.validateForm();
        // eslint-disable-next-line no-loops/no-loops
        while (formRef.current?.isValidating) {
            // eslint-disable-next-line no-await-in-loop
            await new Promise((resolve) => setTimeout(resolve, 1));
        }
        return !!formRef.current?.isValid;
    };

    useEffect(() => {
        // this arrow function is necessary to prevent the state setter from
        // thinking we are just looking for prev state and running validate on render
        setValidate(() => validateForm);
    }, []);

    return (
        <Container>
            <Formik
                innerRef={formRef}
                initialValues={{
                    name: createProductRequest.name || '',
                    shortName: createProductRequest.shortName || '',
                    businessKey: createProductRequest.businessKey || '',
                    description: createProductRequest.description || '',
                    businessUnitBusinessKey: createProductRequest.businessUnitBusinessKey || '',
                    criticalityTier: createProductRequest.criticalityTier || '',
                }}
                validationSchema={FORM_VALIDATION}
                validateOnMount
                onSubmit={() => {}}
            >
                {({ isValid, values }) => {
                    useEffect(() => {
                        setCreateProductRequest((prev) => ({
                            ...prev,
                            name: values.name,
                            shortName: values.shortName,
                            businessKey: values.businessKey,
                            description: values.description,
                            businessUnitBusinessKey: values.businessUnitBusinessKey,
                            criticalityTier: values.criticalityTier,
                        }));
                    }, [values]);
                    useEffect(() => {
                        setIsValid(isValid);
                    }, [isValid]);
                    return (
                        <Form autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h3" align="center" gutterBottom>
                                        Tell us about this Product.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} lg={7}>
                                    <TextField
                                        name="name"
                                        label="Product Name"
                                        required
                                        autoFocus
                                        onFocus={() => setFieldFocusId(1)}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={5}>
                                    {fieldFocusId === 1 && (
                                        <SSCard cardStyle={{ height: '100%' }}>
                                            Give your Product a meaningful name that is recognizable within your
                                            Organization.
                                        </SSCard>
                                    )}
                                </Grid>
                                <Grid item xs={12} lg={7}>
                                    <TextField
                                        name="businessKey"
                                        label="Product Business Key"
                                        required
                                        onFocus={() => setFieldFocusId(2)}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={5}>
                                    {fieldFocusId === 2 && (
                                        <SSCard cardStyle={{ height: '100%' }}>
                                            This is an all lowercase name that is at most 4 characters that can be used
                                            to reference this Product in certain naming conventions.
                                        </SSCard>
                                    )}
                                </Grid>
                                <Grid item xs={12} lg={7}>
                                    <TextField
                                        name="shortName"
                                        label="Product Short Name"
                                        onFocus={() => setFieldFocusId(3)}
                                        placeholder={createProductRequest.businessKey}
                                        InputLabelProps={{
                                            shrink:
                                                !!createProductRequest.shortName || !!createProductRequest.businessKey,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={5}>
                                    {fieldFocusId === 3 && (
                                        <SSCard cardStyle={{ height: '100%' }}>
                                            This is a more friendly, all lowercase name that can be used to reference
                                            this Product in certain naming conventions.
                                        </SSCard>
                                    )}
                                </Grid>
                                <Grid item xs={12} lg={7}>
                                    <ComboBox
                                        name="businessUnitBusinessKey"
                                        label="Business Unit"
                                        options={
                                            organization?.businessUnits
                                                ?.filter(
                                                    (bu) =>
                                                        isPlatformManagerMode ||
                                                        bu.businessUnitBusinessKey !== 'system',
                                                )
                                                ?.map((bu) => ({
                                                    value: bu.businessUnitBusinessKey,
                                                    label: bu.name,
                                                })) || []
                                        }
                                        required
                                        onFocus={() => setFieldFocusId(4)}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={5}>
                                    {fieldFocusId === 4 && (
                                        <SSCard cardStyle={{ height: '100%' }}>
                                            Which group owns this Product&apos;s processes and data within your
                                            Organization?
                                        </SSCard>
                                    )}
                                </Grid>
                                <Grid item xs={12} lg={7}>
                                    <ComboBox
                                        name="criticalityTier"
                                        label="Criticality Tier"
                                        options={
                                            organization?.criticalityTiers?.map((ct) => ({
                                                value: ct.id,
                                                label: ct.name,
                                            })) || []
                                        }
                                        required
                                        onFocus={() => setFieldFocusId(5)}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={5}>
                                    {fieldFocusId === 5 && (
                                        <SSCard cardStyle={{ height: '100%' }}>
                                            Choose how critical this Product is to your Organization.
                                        </SSCard>
                                    )}
                                </Grid>
                                <Grid item xs={12} lg={7}>
                                    <TextField
                                        name="description"
                                        label="Description"
                                        multiline
                                        rows="3"
                                        onFocus={() => setFieldFocusId(6)}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={5}>
                                    {fieldFocusId === 6 && (
                                        <SSCard cardStyle={{ height: '100%' }}>
                                            Describe the purpose of this Product and the value it brings to the
                                            Organization.
                                        </SSCard>
                                    )}
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
}

export const MemoizedNewProductStepDetails = memo(NewProductStepDetails);
