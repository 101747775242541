import { Formik, Form, FormikProps, FormikValues } from 'formik';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import { Container, Grid, Typography } from '@mui/material';
import { TextField } from '../../../components/elements/input/TextField';
import { useBackendService } from '../../../providers/BackendServiceProvider';
import { useLogging } from '../../../providers/LoggingProvider';
import { DrawerFooterAction, useLayout } from '../../../providers/LayoutProvider';

const FormikWrapped = styled(Formik)(({ theme }) => ({
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
}));

const FORM_VALIDATION = Yup.object().shape({
    name: Yup.string().required('Business Unit Name is required'),
    businessUnitBusinessKey: Yup.string()
        .matches(/^[a-z0-9]*$/, 'Business Unit Short Name can only contain lowercase letters and numbers')
        .max(16, 'The max length for a Business Unit Business Key is 16 characters')
        .required('Business Unit Short Name is required'),
    description: Yup.string().required('Description is required'),
});

type Props = {
    organizationId: string;
    cloneAsNew?: boolean;
    existingBusinessUnitId?: string;
    businessUnitName?: string;
    actionCompleteCallback: (label: string, value: string, helpDialogText?: string) => void;
};

export function ManageBusinessUnit({
    organizationId,
    cloneAsNew,
    existingBusinessUnitId,
    businessUnitName = '',
    actionCompleteCallback,
}: Props) {
    const INITIAL_FORM_STATE = {
        name: businessUnitName,
        businessUnitBusinessKey: '',
        description: '',
    };

    const [initialFormState, setInitialFormState] = useState(INITIAL_FORM_STATE);
    const { enqueueSnackbar } = useSnackbar();
    const { getBusinessUnitById, newBusinessUnit, updateBusinessUnit } = useBackendService();
    const { trackTrace } = useLogging();
    const { setRightContextDrawerActions } = useLayout();
    const formRef = useRef<FormikProps<FormikValues>>(null);

    const fetchData = async () => {
        trackTrace(`Loading existing business unit data to edit '${existingBusinessUnitId}'`);
        const response = await getBusinessUnitById({
            organizationId,
            id: existingBusinessUnitId!,
        });
        setInitialFormState({
            name: response.name,
            businessUnitBusinessKey: response.businessUnitBusinessKey,
            description: response.description,
        });
    };

    useEffect(() => {
        if (existingBusinessUnitId) {
            fetchData();
        } else {
            // reset form
            setInitialFormState(INITIAL_FORM_STATE);
        }

        setRightContextDrawerActions([
            {
                action: () => {
                    formRef.current?.submitForm();
                },
                actionText: existingBusinessUnitId ? 'Save' : 'Create',
                actionDisabled: formRef.current?.isSubmitting,
            },
        ]);
    }, [existingBusinessUnitId]);

    const handleSubmit = async (values: any, formik: any) => {
        let resId: string;

        if (existingBusinessUnitId && !cloneAsNew) {
            // Update existing business unit
            trackTrace(`Updating business unit  '${existingBusinessUnitId}'..`);
            const envRes = await updateBusinessUnit({
                organizationId,
                id: existingBusinessUnitId,
                ...values,
            });
            formik.setSubmitting(false);
            resId = envRes.id;
        } else {
            // Create new business unit
            trackTrace('New business unit..');
            const envRes = await newBusinessUnit({
                organizationId,
                ...values,
            });
            formik.setSubmitting(false);
            resId = envRes.id;
        }

        enqueueSnackbar('Business Unit Saved');
        trackTrace(`Success: '${resId}'`);
        actionCompleteCallback(
            `${values.name} (${values.businessUnitBusinessKey.toUpperCase()})`,
            resId,
            values.description,
        );
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Container maxWidth="md">
                    <FormikWrapped
                        innerRef={formRef}
                        initialValues={initialFormState}
                        enableReinitialize
                        validationSchema={FORM_VALIDATION}
                        onSubmit={(values, formik) => handleSubmit(values, formik)}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="name"
                                            label="Business Unit Name"
                                            required
                                            autoFocus={!existingBusinessUnitId ? true : undefined}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="businessUnitBusinessKey"
                                            label="Business Unit Short Name"
                                            disabled={!!(existingBusinessUnitId && !cloneAsNew)}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="description"
                                            label="Description"
                                            helpDialogText="A business description to assist users in understanding the purpose of this tier"
                                            multiline
                                            rows="3"
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </FormikWrapped>
                </Container>
            </Grid>
        </Grid>
    );
}
