import { Grid, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { HorizontalRule } from '@mui/icons-material';
import { mutedColor } from '../../utils/theme';

type Props = {
    item: { label: string; value?: string | ReactElement };
    labelNumColWidth?: number;
    inheritColor?: boolean;
    forceCenter?: boolean;
};

export function KeyValueDisplay({ item, labelNumColWidth = 6, inheritColor, forceCenter }: Props) {
    return (
        <Grid container alignItems={forceCenter ? 'center' : 'start'} paddingY="8px">
            <Grid item container xs={labelNumColWidth} alignItems="center" flexWrap="nowrap">
                <Typography
                    component="span"
                    variant="body2"
                    display="inline-flex"
                    color={inheritColor ? 'inherit' : mutedColor}
                    flex={1}
                >
                    {item.label}
                </Typography>
                <Typography component="span" variant="body2" display="inline-flex" paddingRight={3}>
                    :
                </Typography>
            </Grid>
            <Grid item container xs={12 - labelNumColWidth} alignItems="center" alignSelf="center">
                {item.value ? (
                    <Typography
                        component="span"
                        variant="body2"
                        style={{ whiteSpace: 'pre' }}
                        overflow="hidden"
                        textOverflow="ellipsis"
                    >
                        {item.value}
                    </Typography>
                ) : (
                    <HorizontalRule style={{ verticalAlign: 'top' }} />
                )}
            </Grid>
        </Grid>
    );
}
