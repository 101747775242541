export interface WorkloadTypeDetails {
    label: string;
    value: string;
    isShared: boolean;
    platformMgrOnly?: boolean;
    skipEnableForDeploy?: boolean;
    nonProdProdOnly?: boolean;
}

export enum WorkloadType {
    AppFrontendReactKubernetesWorkload = 'AppFrontendReactKubernetesWorkload',
    AppBackendNodeKubernetesWorkload = 'AppBackendNodeKubernetesWorkload',
    AppNodeKubernetesJobWorkload = 'AppNodeKubernetesJobWorkload',
    CatalogFrontendDocsDocusaurusWorkload = 'CatalogFrontendDocsDocusaurusWorkload',
    DataSourceNodeWorkload = 'DataSourceNodeWorkload',
    SharedKubernetesWorkload = 'SharedKubernetesWorkload',
    SharedAzureDataFactoryWorkload = 'SharedAzureDataFactoryWorkload',
    SharedContainerRegistryWorkload = 'SharedContainerRegistryWorkload',
    SharedFrontdoorWorkload = 'SharedFrontdoorWorkload',
    SharedEventHubWorkload = 'SharedEventHubWorkload',
    SharedLogAnalyticsWorkload = 'SharedLogAnalyticsWorkload',
    CodeRepositoryWorkload = 'CodeRepositoryWorkload',
    SharedGithubRunnerKubernetesWorkload = 'SharedGithubRunnerKubernetesWorkload',
    OrganizationGovernanceWorkload = 'OrganizationGovernanceWorkload',
    SharedGithubComponentsWorkload = 'SharedGithubComponentsWorkload',
    ProductManagementWorkload = 'ProductManagementWorkload',
}

export const WORKLOAD_TYPES: WorkloadTypeDetails[] = [
    { label: 'Frontend App', value: WorkloadType.AppFrontendReactKubernetesWorkload, isShared: false },
    // { label: 'Backend Service - Node Container App', value: 'appBackendNodeContainer' },
    { label: 'Backend Service', value: WorkloadType.AppBackendNodeKubernetesWorkload, isShared: false },
    {
        label: 'Node Kubernetes Job',
        value: WorkloadType.AppNodeKubernetesJobWorkload,
        isShared: false,
        platformMgrOnly: true,
    },
    {
        label: 'Documentation Site',
        value: WorkloadType.CatalogFrontendDocsDocusaurusWorkload,
        isShared: false,
        platformMgrOnly: true,
    },
    { label: 'Data Mesh - Source Aligned Node', value: WorkloadType.DataSourceNodeWorkload, isShared: false },
    { label: 'Shared Kubernetes', value: WorkloadType.SharedKubernetesWorkload, isShared: true },
    {
        label: 'Shared Azure Data Factory',
        value: WorkloadType.SharedAzureDataFactoryWorkload,
        isShared: true,
    },
    {
        label: 'Shared Azure Container Registry',
        value: WorkloadType.SharedContainerRegistryWorkload,
        isShared: true,
    },
    { label: 'Shared Frontdoor', value: WorkloadType.SharedFrontdoorWorkload, isShared: true },
    { label: 'Shared Event Hub', value: WorkloadType.SharedEventHubWorkload, isShared: true },
    { label: 'Shared Log Analytics', value: WorkloadType.SharedLogAnalyticsWorkload, isShared: true },
    {
        label: 'Code Repository',
        value: WorkloadType.CodeRepositoryWorkload,
        isShared: false,
        nonProdProdOnly: true,
    },
    {
        label: 'Shared Github Runner Kubernetes Cluster',
        value: WorkloadType.SharedGithubRunnerKubernetesWorkload,
        isShared: true,
        platformMgrOnly: true,
    },
    {
        label: 'Organization Governance',
        value: WorkloadType.OrganizationGovernanceWorkload,
        isShared: true,
        platformMgrOnly: true,
        skipEnableForDeploy: true,
        nonProdProdOnly: true,
    },
    {
        label: 'Shared Github Components',
        value: WorkloadType.SharedGithubComponentsWorkload,
        isShared: true,
        platformMgrOnly: true,
        nonProdProdOnly: true,
    },
    {
        label: 'Product Management',
        value: WorkloadType.ProductManagementWorkload,
        isShared: true,
        platformMgrOnly: true,
        nonProdProdOnly: true,
    },
];
