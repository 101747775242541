import { Formik, Form, FormikProps, FormikValues } from 'formik';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import { Container, Grid, Typography } from '@mui/material';
import { TextField } from '../../../components/elements/input/TextField';
import { SubmitButton } from '../../../components/elements/input/SubmitButton';
import { useBackendService } from '../../../providers/BackendServiceProvider';
import { Select } from '../../../components/elements/input/Select';
import { useLogging } from '../../../providers/LoggingProvider';
import { DrawerFooterAction, useLayout } from '../../../providers/LayoutProvider';

const FormikWrapped = styled(Formik)(({ theme }) => ({
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
}));

const AVAILABILITY_OPTIONS = [
    { label: '99.999% - Five nines', value: '99999' },
    { label: '99.99% - Four nines', value: '9999' },
    { label: '99.9% - Three nines', value: '999' },
    { label: '99% - Two nines', value: '99' },
    { label: '<90% - One nine or less', value: '9' },
];

const FORM_VALIDATION = Yup.object().shape({
    name: Yup.string().required('Criticality Tier Name is required'),
    businessKey: Yup.string()
        .matches(/^[a-z0-9]*$/, 'Criticality Tier Short Name can only contain lowercase letters and numbers')
        .max(16, 'The max length for a Criticality Tier Short Name is 16 characters')
        .required('Criticality Tier Short Name is required'),
    description: Yup.string().required('Description is required'),
    availabilityTarget: Yup.string().required('Availability Target is required'),
    priority: Yup.number()
        .integer()
        .typeError('Please enter a priority integer number')
        .min(1, 'The lowest a priority value can be is 1')
        .required('Priority is required'),
});

type Props = {
    organizationId: string;
    cloneAsNew?: boolean;
    existingCriticalityTierId?: string;
    criticalityTierName?: string;
    actionCompleteCallback: (label: string, value: string, helpDialogText?: string) => void;
};

export function ManageCriticalityTier({
    organizationId,
    cloneAsNew,
    existingCriticalityTierId,
    criticalityTierName = '',
    actionCompleteCallback,
}: Props) {
    const INITIAL_FORM_STATE = {
        name: criticalityTierName,
        businessKey: '',
        description: '',
        availabilityTarget: '',
        priority: '',
    };

    const [initialFormState, setInitialFormState] = useState(INITIAL_FORM_STATE);
    const { enqueueSnackbar } = useSnackbar();
    const { getCriticalityTierById, newCriticalityTier, updateCriticalityTier } = useBackendService();
    const { trackTrace } = useLogging();
    const { setRightContextDrawerActions } = useLayout();
    const formRef = useRef<FormikProps<FormikValues>>(null);

    const fetchData = async () => {
        trackTrace(`Loading existing criticality tier data to edit '${existingCriticalityTierId}'`);
        const response = await getCriticalityTierById({
            organizationId,
            id: existingCriticalityTierId!,
        });
        setInitialFormState({
            name: response.name,
            businessKey: response.businessKey,
            description: response.description,
            availabilityTarget: response.availabilityTarget,
            priority: response.priority.toString(),
        });
    };

    useEffect(() => {
        if (existingCriticalityTierId) {
            fetchData();
        } else {
            // reset form
            setInitialFormState(INITIAL_FORM_STATE);
        }

        setRightContextDrawerActions([
            {
                action: () => {
                    formRef.current?.submitForm();
                },
                actionText: existingCriticalityTierId ? 'Save' : 'Create',
                actionDisabled: formRef.current?.isSubmitting,
            },
        ]);
    }, [existingCriticalityTierId]);

    const handleSubmit = async (values: any, formik: any) => {
        let resId: string;

        if (existingCriticalityTierId && !cloneAsNew) {
            // Update existing criticality tier
            trackTrace(`Updating criticality tier  '${existingCriticalityTierId}'..`);
            const envRes = await updateCriticalityTier({
                organizationId,
                id: existingCriticalityTierId,
                ...values,
                priority: parseInt(values.priority, 10),
            });
            formik.setSubmitting(false);
            resId = envRes.id;
        } else {
            // Create new criticality tier
            trackTrace('New criticality tier..');
            const envRes = await newCriticalityTier({
                organizationId,
                ...values,
                priority: parseInt(values.priority, 10),
            });
            formik.setSubmitting(false);
            resId = envRes.id;
        }

        enqueueSnackbar('Criticality tier saved');
        trackTrace(`Success: '${resId}'`);
        actionCompleteCallback(`${values.name} (${values.businessKey.toUpperCase()})`, resId, values.description);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Container maxWidth="md">
                    <FormikWrapped
                        innerRef={formRef}
                        initialValues={initialFormState}
                        enableReinitialize
                        validationSchema={FORM_VALIDATION}
                        onSubmit={(values, formik) => handleSubmit(values, formik)}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="name"
                                            label="Criticality Tier Name"
                                            required
                                            autoFocus={!existingCriticalityTierId ? true : undefined}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="businessKey"
                                            label="Criticality Tier Short Name"
                                            disabled={!!(existingCriticalityTierId && !cloneAsNew)}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="description"
                                            label="Description"
                                            helpDialogText="A business description to assist users in understanding the purpose of this tier"
                                            multiline
                                            rows="3"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Select
                                            name="availabilityTarget"
                                            label="Availability Target"
                                            options={AVAILABILITY_OPTIONS}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="priority"
                                            type="number"
                                            inputProps={{ min: '1' }}
                                            label="Priority"
                                            helpDialogText="1 = Most Critical"
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </FormikWrapped>
                </Container>
            </Grid>
        </Grid>
    );
}
